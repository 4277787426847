//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sameAs } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { createHelpers } from "vuex-map-fields";
import ColorLine from "./ColorLine.vue";
import ProgressBar from "./BaseStepProgressBar.vue";
import BaseButton from "./BaseButton.vue";
import TopicForm from './TopicForm.vue';

const { mapFields } = createHelpers({
  getterType: "topics/getField",
  mutationType: "topics/updateField",
});

export default {
  name: "TopicCreator",
  components: {
    ColorLine,
    ProgressBar,
    BaseButton,
    TopicForm,
  },
  data() {
    return {
      currentStep: 1,
      count: 0,
      numberOfSteps: 3,
      stepsTitle: ["O que são os Debates", "Regras", "Crie seu Debate"],
      rulesAccepted: false,
      rulesError: false,
      terms: false,
      hasSelected: false,
    };
  },
  validations: {
    terms: {
      sameAs: sameAs( () => true )
    },
  },
  computed: {
    ...mapGetters({
      categories: "categories/loadCategories",
    }),
    termsErrorMessage() {
      return "É necessário aceitar os termos";
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      this.$refs.topicInfos.$v.$touch();
      if(!this.$v.$anyError && !this.$refs.topicInfos.$v.$anyError) {
        this.$store
        .dispatch("topics/createNewTopic", { $socket: this.$socket })
        .then(() => {
          this.content = "";
          this.title = "";
          this.category = null;
          this.categoriesTagged = [];
          this.$router.push("/topics");
        });
      }
      else {
        this.$q.notify({
          message: "Por favor, preencha todos os campos para o debate",
          position: 'top-right',
        });
      }
    },
    cancel() {
      this.content = "";
      this.title = "";
      this.category = null;
      this.categoriesTagged = [];
      this.$router.push({ name: "Topics" });
    },
    nextStep() {
      if (this.currentStep === 2 && this.rulesAccepted === false) {
        this.rulesError = true;
      } else if (this.count <= this.numberOfSteps) {
        this.currentStep += 1;
        this.count += 1;
        this.scrollToTop();
        this.rulesError = false;
      }
    },
    prevStep() {
      if (this.count >= 0) {
        this.currentStep -= 1;
        this.count -= 1;
      }
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
