//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StepProgressBar',
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
    stepsTitle: {
      type: Array,
      default: () => ['passo 1', 'passo 2', 'passo 3'],
    },
  },
  data() {
    return {
    };
  },
  methods: {},
};
